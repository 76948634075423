
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .visible{
        display: block;
    }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .aboutyuva{
        padding: 30px 60px;
    }
    .container{
        width: 100%;
    }
    .desktop-nav{
        display: none;
    }
    .mobile-nav{
        display: block;
    }
    .luanchBackground{
        background-image: url('../Asset/Images/yuva-banner-launch.png');
    }
    .scholarship-background{
        background-repeat:auto!important;
background-size: auto!important;
height: auto!important;

}
.visible{
    display: none;
}
}
@media only screen and (min-width: 640px) and (max-width: 768px) { 
    .desktop-nav{
        display: none;
    }
    .mobile-nav{
        display: block;
    }
    .aboutyuva{
        padding: 30px 40px;
    }
    .container{
        width: 100%;
    }
    .scholarship-background{
            background-repeat:auto!important;
    background-size: auto!important;
    height: auto!important;

    }
    .luanchBackground{
        background-image: url('../Asset/Images/yuva-banner-launch.png');
        background-repeat:auto!important;
    background-size: auto!important;
    height:100%;
    }
    .visible{
        display: none;
    }
}

@media only screen and (min-width: 300px) and (max-width: 640px) { 
    .nonecss{
        display: none;
    }
    .container{
        width: 100%;
    }
    .desktop-nav{
        display: none;
    }
    .mobile-nav{
        display: block;
    }
    .owl-carousel .owl-nav .owl-prev, .owl-carousel .owl-nav .owl-next, .owl-carousel .owl-dot{
        margin: 0px !important;
    }
    .aboutyuva{
        padding: 0px;
    }
    .rb-tabs .rb-tabs-header .rb-tabs-items{
        display: block!important;
      
    }
    .rb-tabs .rb-tabs-header .rb-tabs-items .rb-tabs-item{
        margin-bottom: 4px!important;
    }
    .luanchBackground{
        background-image: url('../Asset/Images/yuva-banner-launch.png');
        background-repeat:auto!important;
    background-size: auto!important;
    height:100%;
    }
    .scholarship-background{
            background-repeat:auto!important;
    background-size: auto!important;
    height: auto!important;

    }
    .launch-height{
        height: auto!important;
    }
    .visible{
        display: none;
    }
    .melodies-background-image {
        background-position: center;
      }
      
      .capture-background-image {
        background-position: center;
      }
      
      .artist-background-image {
        background-position: center;
      }
      
      .rythms-background-image {
        background-position: center;
      }
      
      .short-films-background-image {
        background-position: center;
      }
      
      .sports-background-image {
        background-position: center;
      }
      
}
* {
  font-family: 'Merriweather', serif;
  letter-spacing: 1px;
}
img {
  width: 100%; /* or max-width: 100%; */
  height: auto;
}


.sub-heading {
  font-family: 'Bakbak One', cursive;
}

.architects-daughter-font {
  font-family: 'Architects Daughter', cursive;
}

.launch {
  font-family: 'Luckiest Guy', cursive;
}

.kalam-font {
  font-family: 'Rampart One', cursive;
}

.transition07 {
  transition: 0.7s
}

.container {
  width: 1336px;
}

.border-radius {
  border-radius: 6px;
}

.aboutyuva {
  position: relative;
}

.ImageHighlighted {
  position: absolute;

}

.ImageHighlight {
  position: absolute;
  left: 30%;
}

.hoverColor {
  background-color: rgba(0, 0, 0, 0.72);
}

.primaryHover {
  background-color: rgba(255, 161, 51, 0.63);
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
  background-color: #ff8f0b9c !important;
}

.fa-angle-left,
.fa-angle-right {
  color: #fff;
  font-size: 26px !important;
}

.element {
  transform: translateY(0px);
  transition: 0.5s;
  overflow: none !important;
}

.cardElemts {
  transform: translateY(800px);
  /* display: none; */
  transition: 0.7s;
}

.cardHeader {
  transform: translateY(250px);
  transition: 0.5s;
}

.element:hover .cardHeader {
  transform: translateY(50px);
}

.element:hover .cardElemts {
  transform: translateY(120px);
  display: block;
  /* background-color: rgba(0, 0, 0, 0.72); */
}

.element:hover .primaryHover {
  background-color: rgba(255, 161, 51, 0);
}

.element:hover .elementTextCard {
  transform: translateY(0px);

}

.elementTextCard {
  transform: translateY(100px);
  transition: 0.7s;
  /* background-color: rgba(255, 161, 51, 0); */
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logoCss {
  position: absolute;
  top: 0%;
}

.boxShadow {
  box-shadow: 11px 10px 38px 0px rgb(0 0 0 / 10%)
}

.borderColor {
  border: 1px solid #939496;
}
.borderWhiteColor {
  border: 1px solid #fff;
}

.bor1 {
  border: 1px solid #aa4029;
}

.center{
  display: flex;
  justify-content: center;
  align-items: center;
}
.thankyou{
  font-family: 'Marck Script', cursive;
}

.thank {
  background-image: url("../Asset/Images/popup.png");
}

.backgroundImage {
  background-image: url('../Asset/Images/Home/Timer.jpg');
  object-fit: cover;
}

.backgroundimage1 {
  /* background: url('../Asset/HomeImage.png'); */
  background-repeat: no-repeat;
  background-size: cover;
  /* background-size: 100%;  */
}

.melodies-background-image {
  background-image: url('../Asset/Images/Activity-Club/music.png');
  background-repeat: no-repeat;
  /* background-position: center; */
}

.capture-background-image {
  background-image: url('../Asset/Images/Activity-Club/capture.png');
  background-repeat: no-repeat;
  /* background-position: center; */
}

.artist-background-image {
  background-image: url('../Asset/Images/Activity-Club/artist.png');
  background-repeat: no-repeat;
  /* background-position: center; */
}

.rythms-background-image {
  background-image: url('../Asset/Images/Activity-Club/rythms.png');
  background-repeat: no-repeat;
  /* background-position: center; */
}

.short-films-background-image {
  background-image: url('../Asset/Images/Activity-Club/short-films.png');
  background-repeat: no-repeat;
  /* background-position: center; */
}

.sports-background-image {
  background-image: url('../Asset/Images/Activity-Club/sports.png');
  background-repeat: no-repeat;
}

.luanchBackground {
  background-image: url('../Asset/Images/Yuva-Launch.png');
  background-repeat: no-repeat;
  background-size: 100%;
  height: 100vh;
  position: relative;
}

.TechfromTech {
  position: absolute;
}

.launch-rocket {
  position: absolute;
  bottom: 5%;
}

.readMore::before {
  background-color: #049013;
  content: '';
  position: absolute;
  bottom: 0;
  z-index: 1;
  height: 2px;
  transition: 0.4s;
}


.relative-banner {
  position: relative;

  text-align: center;
  color: white;
}

.aboutyuva {
  padding: 30px 120px;
}


/* ---New Website  */

.active {
  border-bottom: 5px solid #fff;
  width: 50%;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.sub-banner {
  position: absolute;
  top: 70%;
  left: 10%;
}

.mobile-nav {
  display: none;
}

.mobile-view-border {
  border-bottom: 2px solid #fff;
}

.mobile-view {
  display: none;
}

.stro{
  -webkit-text-stroke-width: 0.5px;
  -webkit-text-stroke-color: white;
}

/* brouchre */

/* .backgrouncolor {
  background: linear-gradient(90deg, rgba(255,161,48,1) 0%, rgba(255,161,51,1) 5%, rgba(255,161,51,1) 10%, rgba(79,177,166,1) 50%, rgba(111,190,228,1) 100%);
} */
.ploy {
  clip-path: polygon(0 0, 90% 0, 60% 100%, 0 100%);
  height: 80vh;
  /* filter: drop-shadow(0 15px 30px rgba(0, 0, 200, 0.5)); */
  /* filter: drop-shadow(-1px 6px 3px rgba(50, 50, 0, 0.5)); */
  background: linear-gradient(90deg, rgba(255, 161, 48, 1) 0%, rgba(255, 161, 51, 1) 5%, rgba(255, 161, 51, 1) 10%, rgba(79, 177, 166, 1) 50%, rgba(111, 190, 228, 1) 100%);
}

.poly {
  clip-path: polygon(0% 0, 100% 0, 100% 50%, 0% 50%);
  background-color: aqua;
}

.ployback {
  filter: drop-shadow(0.5em .75em 0px #403F3E);
  /* filter: drop-shadow(-1px 6px 3px rgba(50, 50, 0, 0.5)); */
}

.scholar {
  /* background-image: url('..//Images/scholarmusic.jpg'); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.glass {
  background: rgba(255, 255, 255, 0.66);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(0px);
-webkit-backdrop-filter: blur(0px);
border: 1px solid rgba(255, 255, 255, 0.84);
}

.test{
  background-color: white;
  padding-top: 10px;
}

/* home page Slide up */

.scale-up-bottom {
  -webkit-animation: scale-up-bottom 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: scale-up-bottom 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-3-12 10:53:34
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-up-bottom
 * ----------------------------------------
 */
@-webkit-keyframes scale-up-bottom {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
  }
}

@keyframes scale-up-bottom {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
  }
}

.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-3-22 15:43:26
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-up-center
 * ----------------------------------------
 */
 @-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}


.borderRadius50{
  border-radius: 50%;
}
.scholarship-background{
  background-image: url('../Asset/Images/Scholarship/scholar-ship-banner.webp');
  /* background-repeat: no-repeat; */
  /* background-size: 100%; */
  width: 100%;
}
.borderSecondaryLeft{
  /* box-shadow: 0px 0px 0px 1px  #403F3E inset; */
  border-left:1px solid #939496 ;
  border-bottom:1px solid #939496 ;

  /* -webkit-box-shadow: 0 0 10px 0 #000000;
	-moz-box-shadow: 0 0 10px 0 #000000;
	box-shadow: 0 0 10px 0 #000000; */

}
.borderPrimary{
  border: 5px solid #FFA133;
}
.thankpopup{
  background-image: url('../Asset/Images/thankpop.webp');
  background-repeat: no-repeat;
  background-position: center;

}


.ReactModal__Overlay {
  background-color: rgb(22 20 20 / 75%) !important;
}

/* .ReactModal__Overlay--after-open{

} */



.prev-slide{
    background:rgba(255, 255, 255, 0.2) !important;
    position: absolute;
    left:0%;
    top:50%;
}
.owl-carousel .owl-nav .owl-prev, .owl-carousel .owl-nav .owl-next, .owl-carousel .owl-dot{
    margin: 0px 40px;
}
.owl-prev{
    background:rgba(253, 253, 253, 0) !important;
    color: #fff!important;
    position: absolute;
    /* border: 1px solid #ccc  !important; */
    transition: 0.5s;
    padding: 3px !important;
    left:0%;
    top:45%;
    /* color:#7C0201!important; */
    height: 55px;
    /* color:#7C0201!important; */
    width: 40px;
    border-radius: 50%;
}
.owl-prev:hover,.owl-next:hover{
    color: #fff !important;
}

.fa-angle-left,.fa-angle-right{
   font-size: 18px;
}
.owl-next{
    background:rgba(253, 253, 253, 0) !important;
    color: #fff!important;
    position: absolute;
    transition: 0.5s;
    right:0%;
    top:45%;
    height: 55px;
    /* color:#7C0201!important; */
    width: 40px;
    border-radius: 50%;
}
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span{
    background-color: #1589ee!important;
    transition: 0.5s;
    width: 15px;
    height: 5px;
}
.owl-theme .owl-dots .owl-dot span{
    width: 20px!important;
    background-color: #d7e9fa!important;
    transition: 0.5s;
    height: 4px!important;
}
.arrow-button{
    border: 1px solid #ccc;
}

.rb-tabs-items {
    width: 100%;
}
.rb-tabs .rb-tabs-header .rb-tabs-items .rb-tabs-item{
    width: auto;
}
.rb-tabs .rb-tabs-header .rb-tabs-items .rb-tabs-item{
    background-color: #403F3E;
    padding: 18px 10px!important;
    color:#fff;
    font-size: 20px;
    
}
.rb-tabs .rb-tabs-header .rb-tabs-items .rb-tabs-item.active{
    background-color: #FFA133;
    border-bottom:0px soild red!important;
}

.swiper {
    position: inherit !important;
    padding: 30px 0px 60px 0px;
  }
  .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    left: -44px !important;
  }
  .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
    right: -44px !important;
    position: absolute !important ;
  }
  .swiper-button-next,
  .swiper-button-prev {
    z-index: 5 !important;
  }
  .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
  .swiper-pagination-horizontal.swiper-pagination-bullets
    .swiper-pagination-bullet {
    height: 5px !important;
    width: 5px;
    transition: 0.5s;
  }
  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    z-index: 5 !important;
  }
  .swiper-pagination-bullet-active {
    width: 20px !important;
    border-radius: 4px !important;
  }

  #parallelogram-container {
    margin: 0 50px;
  }
  
  .parallelogram {
      background: #fff;
      border: 		none;
      display: 		inline-block;
      -moz-transform: 	scaleX(1) scaleY(1) scaleZ(1) skewX(-20deg);
      -webkit-transform:  scaleX(1) scaleY(1) scaleZ(1) skewX(-20deg);
      transform: 		scaleX(1) scaleY(1) scaleZ(1) skewX(-20deg);
      transform-origin: 	50% 50%;
      padding: 		0px;
      margin:             0 1px;
  }
  

  
  .parallelogram-btn {
    width: 60px;
      background: #ffa008;
      color: 		#FFF;
      border:             none;
      display: 		inline-block;
      height: 		90px;
      -moz-transform: 	scaleX(1) scaleY(1) scaleZ(1) skewX(-20deg);
      -webkit-transform: 	scaleX(1) scaleY(1) scaleZ(1) skewX(-20deg);
      transform: 		scaleX(1) scaleY(1) scaleZ(1) skewX(-20deg);
      border-bottom-right-radius:      5px;
      border-top-right-radius:      5px;
      transform-origin: 	50% 50%;
      padding: 		0px;
      margin: 		0px;
      font-weight: 	700;
      cursor: 		pointer;
  }